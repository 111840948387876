import React,{useState, useEffect} from "react";
import {useForm} from 'react-hook-form';
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import { FormBox, ErrBox, TimerBox, LabelBox, ViewLabel, LoginBox } from "./Register.styled";
import Title from "../../atom/Title/Title";
import Btn from "../../atom/Btn/Btn";

const SignUp = (props) => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: {errors},
        setError,
        clearErrors,
        getValues,
        setValue,
    } = useForm();
    const [isLoading, setIsLoading] = useState(false);

    //개인정보 약관 동의
    const [agreed, setAgreed] = useState(false);
    const handleAgree = (isChecked) => {
        setAgreed(isChecked);
    };

    //아이디 중복체크
    const [idToCheck, setIdToCheck] = useState(''); // 중복 체크할 아이디 상태
    const [isIdChecked, setIsIdChecked] = useState(false); // 아이디 중복 체크 여부 상태
    const checkAccountIdDuplication = async (user_id) =>{
        try {
            if(idToCheck===''){
                alert('아이디를 입력해주세요')
            }else{
                const response = await axios.post('/user/api/v1/id-validation', {
                    user_id: idToCheck
                },{headers: {
                    "Content-Type": "application/json",}});
                // console.log(response.data);

                if(response.data.code==='0001'){
                    setIsIdChecked(true);
                    alert('사용가능한 아이디 입니다 :)')
                }else if(response.data.code==='0002'){
                    alert('이미 사용중인 아이디 입니다!')
                }
            }
        } catch (error) {
            // console.log('아이디 중복 체크 실패:', error);
            alert('오류가 발생했습니다. 담당자에게 문의해주세요!');
        }
    }

        // // 핸드폰 번호 입력 필드에 대한 추가 로직
        // const handlePhoneNumberChange = (e) => {
        //     const phoneNumber = e.target.value;
        //     let formattedPhoneNumber = addHyphenToPhoneNumber(phoneNumber); // 핸드폰 번호에 하이픈 추가
        //     // 입력된 번호가 하이픈으로 끝나는 경우에는 하이픈을 지움
        //     if (formattedPhoneNumber.endsWith('-')) {
        //         formattedPhoneNumber = formattedPhoneNumber.slice(0, -1);
        //     }

        //     setValue('hp', formattedPhoneNumber); // 입력값 업데이트
        //     // 입력된 번호가 하이픈으로 끝나는 경우에는 하이픈을 지움

        // };
        // // 핸드폰 번호에 하이픈 추가하는 함수
        // const addHyphenToPhoneNumber = (phoneNumber) => {
        //     // 하이픈 추가 로직 작성
        //     const numbersOnly = phoneNumber.replace(/[^0-9]/g, ''); // 숫자 이외의 문자 제거
        //     let formattedPhoneNumber = '';

        //     if (numbersOnly.length >= 4) {
        //     formattedPhoneNumber += numbersOnly.substr(0, 3) + '-';
        //     formattedPhoneNumber += numbersOnly.substr(3, 4) + '-';
        //     formattedPhoneNumber += numbersOnly.substr(7);
        //     } else if (numbersOnly.length >= 3) {
        //     formattedPhoneNumber += numbersOnly.substr(0, 3) + '-';
        //     formattedPhoneNumber += numbersOnly.substr(3);
        //     } else {
        //     formattedPhoneNumber = numbersOnly;
        //     }
        //     return formattedPhoneNumber;
        // };

        const [hpToCheck, setHpToCheck] = useState(''); // 중복 체크할 핸드폰번호 상태
        // 인증확인
        const [isHpChecked, setIsHpChecked] = useState(false);   //핸드폰 확인 체크 여부 상태
        const [authChecked,setAuthChecked] = useState(false);   //인증번호 확인 체크 여부 상태
        // 핸드폰 중복 체크
        const checkHpDuplication = async(hp) => {
            if(hpToCheck===''){
                alert('핸드폰번호를 입력해주세요')
            }else{
                try {
                    const hpPattern = /^\d{11}$/;
                    if(!hpToCheck.match(hpPattern)){
                        alert('올바른 핸드폰 번호 형식을 입력해주세요')
                    }else{
                        const response = await axios.post('/user/api/v1/hp-validation', {
                            hp: hpToCheck
                        },{headers: {
                            "Content-Type": "application/json",}});
                        // console.log(response.data);

                        if(response.data.code==='0003'){
                            setIsHpChecked(true);
                            sendAuth();
                        }else if(response.data.code==='0004'){
                            alert('이미 존재하는 휴대폰번호입니다!')
                        }
                    }
                }catch (error) {
                // console.log('아이디 중복 체크 실패:', error);
                    alert('오류가 발생했습니다. 담당자에게 문의해주세요!');
                }
            }
        }
        const [authToCheck, setAuthToCheck] = useState(''); //인증번호 상태
        // 인증번호 전송
        const sendAuth = async(hp)=>{
            try {
                const response = await axios.post('user/api/v1/auth-message', {
                    hp: hpToCheck
                },{headers: {
                    "Content-Type": "application/json",}});
                // console.log(response.data);

                if(response.data.message==='OK'){
                    alert('인증번호가 전송되었습니다!')
                    startTimer();   // 인증번호 전송 버튼을 클릭할 때 타이머를 시작하도록 호출
                }else{
                    alert('오류가 발생했습니다. 담당자에게 문의해주세요!')
                }
            } catch (error) {
                alert('오류가 발생했습니다. 담당자에게 문의해주세요!');
            }
        }

        //인증번호 5분 타이머 설정
        const [isTimerRunning, setIsTimerRunning] = useState(false); // 타이머가 실행 중인지 여부
        const [countdown, setCountdown] = useState(300); // 초 단위로 설정 (5분 = 300초)
        const [timerId, setTimerId] = useState(null); // 타이머 식별자
        useEffect(() => {
            let timer;

            // 타이머가 실행 중이고 countdown이 0보다 크면 1초마다 countdown 값을 감소시킵니다.
            if (isTimerRunning && countdown > 0) {
              timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
              }, 1000);
            }
            // countdown이 0이 되면 타이머를 중지합니다.
            if (countdown === 0) {
              setIsTimerRunning(false);
              clearInterval(timerId); // 타이머가 종료되면 클리어
              setTimerId(null);
            }
            // 컴포넌트가 언마운트되면 타이머를 정리합니다.
            return () => {
              clearInterval(timer);
            };
        }, [isTimerRunning, countdown, timerId]);

        // 타이머 시작 함수
        const startTimer = () => {
            setIsTimerRunning(true);
            setCountdown(300); // 5분 타이머를 시작할 때 countdown을 다시 300으로 설정
            // 이미 실행 중인 타이머를 클리어하고 다시 시작
            if (timerId) {
                clearInterval(timerId);// 이미 실행 중인 타이머 클리어
            }
            const newTimerId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
            setTimerId(newTimerId); // 새 타이머 식별자 설정
        };

        // 인증번호 확인
        const checkAuth = async(hp, auth) => {
            try {
                if(isTimerRunning){
                    const response = await axios.post('user/api/v1/auth-check', {
                        hp: hpToCheck,
                        auth: authToCheck
                    },{headers: {
                        "Content-Type": "application/json",}});
                    console.log(response.data);

                    if(response.data.code==='0000'){
                        alert('인증이 완료되었습니다');
                        setAuthChecked(true);
                    }else{
                        alert('인증번호가 틀렸습니다')
                    }
                }else{
                    alert('인증번호가 만료되었습니다')
                }
            } catch (error) {
                alert('오류가 발생했습니다. 담당자에게 문의해주세요!');
            }
        }

        //개인정보, 마케팅 동의
        const [agreeUserInfo, setAgreeUserInfo] = useState(false);
        const [agreeMarketing, setAgreeMarketing] = useState(false);
        const [viewUserInfo, setViewUserInfo] = useState(false);
        const [viewMarketing, setViewMarketing] = useState(false);

        const handleAllCheckboxChange = (event)=>{
            const isChecked = event.target.checked;
            setAgreeUserInfo(isChecked);
            setAgreeMarketing(isChecked);
        }
        //레시피 추천 on/off
        const [recipeOn, setRecipeOn] = useState(false);


    // onvalid 회원가입 form 제출
    const onValid = async({ user_id, password, user_email, hp, auth}) => {
        try{
            setIsLoading(true);
            // 첫 번째 post 요청에 대한 AbortController
            const controller1 = new AbortController();
            const timeoutId1 = setTimeout(() => controller1.abort(), 30000);

            if(isIdChecked&&authChecked){
                if(agreeUserInfo){
                    const response = await axios.post('/user/register/',{
                        user_id: user_id,
                        password: password,
                        user_email: user_email,
                        hp: hp,
                        auth: auth,
                        recipe_recommend: recipeOn,
                        agree_UserInfo: agreeUserInfo,
                        agree_Marketing: agreeMarketing,
                        login_type: 'EMAIL'
                    },{headers: {
                        "Content-Type": "application/json",},
                    signal: controller1.signal,});
                    clearTimeout(timeoutId1);

                    if (response.status >= 200 && response.status < 300) {
                        // 첫 번째 요청 성공적인 응답 처리
                        if(response.data.code==='0000'){
                            alert( '회원 가입이 완료되었습니다!');
                            navigate('/login');
                        }else if(response.data.code==='1001'){
                            alert('이미 존재하는 이메일 입니다')
                        }
                    } else {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }
                }
                else{
                    alert('개인정보 동의를 진행해주세요!')
                }
            }else{
                alert('인증번호 확인을 진행해주세요!')
            };
        }catch(err){
            alert('오류가 발생했습니다. 담당자에게 문의해주세요!');
        }finally{
            setIsLoading(false);
        }
    }
    return(
        <ThemeProvider
            breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
            minBreakpoint="xxs"
        >
            <Title onClick={()=>navigate("/")} className="big_logo mt-4" value="뉴지엄랩" />
            <LoginBox>
            <FormBox>
                <Form onSubmit={handleSubmit(onValid)} className="mt-4">
                    <Form.Group className="mb-3 row-flex">
                    <span class="material-symbols-outlined">person</span>
                        <Form.Control
                            {...register('user_id', {
                                required: 'ID를 입력해주세요 :)'
                            })}
                            placeholder='아이디' type='text'
                            onChange={(event)=>setIdToCheck(event.target.value)}
                            disabled={isIdChecked}
                            />

                        {isIdChecked?null
                            :<Btn size="sm" type="button" className="primary" onClick={checkAccountIdDuplication} value="아이디 확인" />}

                    </Form.Group>
                    { errors.user_id && !idToCheck?<ErrBox>{errors.user_id.message}</ErrBox>:null}

                    <Form.Group className="mb-3 row-flex">
                        <span class="material-symbols-outlined">lock</span>
                        <Form.Control
                            {...register('password', {
                                required: '비밀번호를 입력해주세요 :)',
                                minLength: {
                                    value: 4,
                                    message: '😭 비밀번호를 4자 이상 입력해주세요',
                                },
                            })}
                            placeholder='비밀번호' type='password'
                            error={errors.password}/>
                    </Form.Group>
                    {errors.password ? (
                                <ErrBox>{errors.password.message}</ErrBox>):null}

                    <Form.Group className="mb-3 row-flex">
                        <span class="material-symbols-outlined none">lock</span>
                        <Form.Control
                            {...register('password2', {
                                required: '비밀번호를 확인해주세요',
                                minLength: {
                                    value: 4,
                                    message: '😭 비밀번호를 4자 이상 입력해주세요',
                                },
                                validate: (value) => {
                                    const { password } = getValues(); // 다른 필드의 값에 접근하기 위해 getValues() 함수를 사용합니다.
                                    return value === password || '비밀번호가 일치하지 않습니다 :('; // 비밀번호 확인이 일치하지 않으면 에러 메시지를 반환합니다.
                                },
                            })}
                            placeholder='비밀번호 확인' type='password'
                            error={errors.password2}/>
                    </Form.Group>
                    {errors.password2 ? (
                                <ErrBox>{errors.password2.message}</ErrBox>):null}

                    <Form.Group className="mb-3 row-flex">
                    <span class="material-symbols-outlined">phone_iphone</span>
                        <Form.Control
                        id="hp"
                        {...register('hp', {
                            required: '휴대폰 번호를 입력해주세요',
                            pattern: {
                                value: /^\d{11}$/,
                                message: '올바른 휴대폰 번호 형식을 입력해주세요.',
                            }})}
                        onChange={(event)=>setHpToCheck(event.target.value)}
                        placeholder='01012345678' type='tel'
                        error={errors.hp}
                        disabled={authChecked}
                        />
                        {isHpChecked?
                            authChecked?null
                            :<Btn size="sm" type="button" className="primary" onClick={checkHpDuplication} value="휴대폰 재전송" />
                            :<Btn size="sm" type="button" className="primary" onClick={checkHpDuplication} value="휴대폰 인증" />}
                    </Form.Group>
                    {/* 타이머 표시 */}
                    {isTimerRunning && !authChecked && <TimerBox>남은 시간: {Math.floor(countdown / 60)}:{countdown % 60 < 10 ? `0${countdown % 60}` : countdown % 60}</TimerBox>}
                    {errors.hp&&!hpToCheck?(<ErrBox>{errors.hp.message}</ErrBox>):null}

                    <Form.Group className="mb-3 row-flex">
                    <span class="material-symbols-outlined">key</span>
                        <Form.Control
                            id="auth"
                            {...register('auth', {
                                required: '인증번호를 입력해주세요',
                                pattern: {
                                value: /^\d+$/,
                                message: '올바른 인증번호를 입력해주세요',
                                disabled: true,
                                },
                            })}
                            type='auth'
                            placeholder="인증번호"
                            onChange={(event)=>setAuthToCheck(event.target.value)}
                            name="auth"
                            error={errors.auth}
                            disabled={authChecked}
                            />
                            {isHpChecked?
                                authChecked?null:
                                <Btn size="sm" type="button" className="primary" onClick={checkAuth} value="인증번호 확인" />
                            :null}
                    </Form.Group>
                    {errors.auth && !authToCheck ? (<ErrBox>{errors.auth.message}</ErrBox>):null}

                    <Form.Group className="mb-4 row-flex">
                    <span class="material-symbols-outlined">mail</span>
                        <Form.Control
                            id="user_email"
                            {...register('user_email', {
                                required: '이메일을 입력해주세요',
                                pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: '올바른 이메일 형식을 입력해주세요.',
                                disabled: true,
                                },
                            })}
                            placeholder='000@naver.com' type='email'
                            name="user_email"
                            error={errors.user_email}
                            />
                    </Form.Group>
                    {errors.user_email ? (<ErrBox>{errors.user_email.message}</ErrBox>):null}

                    <Form.Group className="mb-4">
                        {/* 개인정보 수집 및 이용 동의서 */}
                        <Form.Check className="mb-3 raio_btn"
                            type={'checkbox'}
                            checked={agreeUserInfo&&agreeMarketing?true:false}
                            onChange={handleAllCheckboxChange}
                            id={`default-checkbox`}
                            label={`이용약관 및 개인정보수집, 마케팅 수신(선택)에 모두 동의합니다`}
                        />

                        <div style={{padding:'0 1rem'}} className="row-flex space-between label_font">
                            <Form.Check className="raio_btn"
                                checked={agreeUserInfo}
                                onChange={()=>{setAgreeUserInfo(!agreeUserInfo)}}
                                type={'checkbox'}
                                id={`default-checkbox`}
                                label={`개인정보 수집 및 이용에 동의하십니까?*`}
                            />
                            <LabelBox onClick={()=>setViewUserInfo(!viewUserInfo)}>{viewUserInfo?'닫기':'자세히'}</LabelBox>
                        </div>
                        {viewUserInfo?<ViewLabel className="label_font mb-3">
                        본 개인정보보호 약관(이하 "약관")은 NUSEUM이 회원 개인정보를 보호하기 위해 제공되는 서비스(이하 "서비스")의 이용과 관련하여 회원의 동의, 권리 및 의무를 규정합니다
                        {'\n'}
                        1. 개인정보 수집 및 이용
                        (주)뉴지엄랩은 회원의 개인정보를 수집 및 이용합니다. 수집되는 개인정보의 종류 및 수집목적은 서비스 제공, 회원 관리, 고객 지원 등을 위한 것입니다.
                        회원은 개인정보 제공에 동의하지 않을 경우, 일부 서비스 이용이 제한될 수 있습니다.
                        {'\n'}
                        2. 개인정보의 제3자 제공 및 공유
                        회원의 개인정보를 제3자에게 제공하는 경우에는 명확한 동의 절차를 거쳐야 합니다. 개인정보보호법에서는 개인정보의 제3자 제공을 예외적으로 허용하는 경우를 명시하고 있으므로, 해당 예외사항을 고려하여 작성해야 합니다.
                        {'\n'}
                        3. 개인정보의 보유 및 삭제
                        (주)뉴지엄랩은 회원의 개인정보를 회원 탈퇴 시까지 보유하며, 보유 기간 종료 혹은 회원 탈퇴 시에는 즉시 파기되거나 익명화 처리됩니다.
                        다만, 관련 법령에 따라 보존이 필요한 경우에는 해당 법령에 따라 보관합니다.
                        {'\n'}
                        4. 개인정보 보호 조치
                        (주)뉴지엄랩은 회원의 개인정보를 보호하기 위해 최선의 노력을 다합니다. 개인정보 보호를 위해 보안 시스템 및 절차를 강화하고 있으며, 외부로부터의 무단 접근, 누출, 변경 또는 손상으로부터 회원의 개인정보를 보호하기 위한 조치를 취합니다.
                        {'\n'}
                        5. 회원의 권리와 의무
                        회원은 개인정보에 대한 열람, 정정, 삭제를 요청할 수 있습니다. 회원은 개인정보의 제공에 동의하지 않을 권리가 있으며, 이 경우 일부 서비스 이용이 제한될 수 있습니다.
                        회원은 약관에 명시된 개인정보 처리 방침에 동의하는 것을 전제로 서비스를 이용해야 합니다.
                        {'\n'}
                        6. 개인정보보호 관련 문의
                        개인정보보호 관련 문의사항은 아래 연락처로 문의하실 수 있습니다: (주)뉴지엄랩{'\n'}
                        카카오톡 @뉴지엄{'\n'}
                        주소 (08812) 서울특별시 관악구 호암로 24길 6. 서울대학교 캠퍼스타운 창업 HERE-RO5 3층 306 뉴지엄랩{'\n'}
                        이메일 nuseum@nuseum-lab.com
                        </ViewLabel>:null}
                        <div style={{padding:'0 1rem'}} className="row-flex space-between label_font mb-1">
                            <Form.Check
                            className="label_font raio_btn"
                                checked={agreeMarketing}
                                onChange={()=>{setAgreeMarketing(!agreeMarketing)}}
                                type={'checkbox'}
                                id={`default-checkbox`}
                                label={`마케팅 수신을 동의하십니까?(선택)`}
                            />
                            <LabelBox onClick={()=>setViewMarketing(!viewMarketing)}>{viewMarketing?'닫기':'자세히'}</LabelBox>
                        </div>
                        {viewMarketing?<ViewLabel className="label_font mb-2">
                        개인정보보호법 제22조 제4항에 의해 선택정보 사항에 대해서는 기재하지 않으셔도 서비스를 이용하실 수 있습니다.
                        {'\n'}
                        1. 마케팅 및 광고에의 활용신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다.
                        {'\n'}
                        2. (주)뉴지엄랩은 서비스를 운용함에 있어 각종 정보를 서비스 화면, 전화, e-mail, SMS, 우편물, 앱푸시 등의 방법으로 뉴지엄 회원에게 제공할 수 있으며, 뉴지엄 모바일 쿠폰의 수신 등, 의무적으로 안내되어야 하는 정보성 내용은 수신동의 여부와 무관하게 제공됩니다.
                        </ViewLabel>:null}
                    </Form.Group>
                    {/* 레시피 추천 on/off
                    <div className="column-flex">
                        레시피를 추천받으시겠습니까?
                        <div className="row-flex">
                        <Btn size="sm" type="button" className={recipeOn?"secondary m-1 p-2":"third m-1 p-2"} onClick={()=>{setRecipeOn(true)}} value="네, 추천받겠습니다" />
                        <Btn size="sm" type="button" className={recipeOn?"third m-1 p-2":"secondary m-1 p-2"} onClick={()=>{setRecipeOn(false)}} value="아니오, 괜찮습니다" />
                        </div>
                    </div> */}

                    {isLoading ? (
                        <div style={{textAlign:'center'}}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                        ) : (
                        <Btn variant="primary" size="lg" type="submit" className="full primary mt-5" value="회원가입" />
                    )}
                    <Link style={{ textDecoration: 'none'}} to='/login'>
                        <Btn variant="secondary" size="lg" className="full lightgray" value="로그인" />
                    </Link>
                    <Btn onClick={()=>navigate('/')}
                                style={{border:'none'}}
                                variant="secondary" size="sm" className="grayborder" value="돌아가기" />
                </Form>
            </FormBox>
            </LoginBox>
        </ThemeProvider>
    )
}

export default SignUp;
